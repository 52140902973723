import formatDate from 'dateformat';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { removeProduitFromPanier } from '../../../store/panier/actions';
import { LignePanier, PanierState } from '../../../store/panier/types';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import BtnPoubelle from '../../BtnPoubelle/BtnPoubelle';
import EditCommentaire from '../../Commentaire/EditCommentaire/EditCommentaire';
import Img from '../../Img/Img';
import Label from '../../Label/Label';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import Prix from '../../Prix/Prix';
import Table from '../../Table/Table';
import { RowProps } from '../../Table/types';
import styles from './TableProduits.module.scss';

export interface Props {
	error?: string;
	loading?: boolean;
	lignes: LignePanier[];
	dateLivraison: PanierState['dateLivraison'];
	removeProduitFromPanier: typeof removeProduitFromPanier;
}

export function _TableProduits({
	error,
	loading,
	lignes,
	dateLivraison,
	removeProduitFromPanier,
}: Props) {
	let livraisonDansMoinsDe2Jours = false;
	if (dateLivraison) {
		const today = new Date();
		const diff =
			dateLivraison.getTime() - new Date(today.toDateString()).getTime();
		const diffInDays = diff / (1000 * 3600 * 24);
		if (diffInDays < 2) livraisonDansMoinsDe2Jours = true;
	}

	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Votre panier est vide"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				{ content: 'Commentaire', center: true },
				{ content: 'Labels' },
				{ content: 'Prix' },
				{ content: 'Quantité' },
				{ content: 'Total' },
				{ content: 'Enlever', center: true, isButton: true },
			]}
			rows={lignes.map((l) => {
				const livraisonIncertaine =
					livraisonDansMoinsDe2Jours &&
					['4eme-gamme', '5eme-gamme', 'surgeles'].includes(l.categorie.slug);

				const today = new Date();
				const dateDans2Jours = today.setDate(today.getDate() + 2);

				const image = (
					<Img
						src={l.imageSrc}
						alt={l.nom}
						fallback="jpg"
						className={styles.photo}
						otherSizes={photosProduitsSizes}
						mediaQueries={[{ width: 80 }]}
					/>
				);

				const errorMessage = l.disabled
					? `Ce produit n'est plus disponible, veuillez le supprimer`
					: l.interdit
						? `Ce produit vous est interdit, veuillez le supprimer`
						: livraisonIncertaine
							? `Nous ne pouvons pas vous garantir la livraison de ce produit avant le ${formatDate(
								dateDans2Jours,
								'dd/mm/yyyy',
							)}`
							: l.nouveauPrix && `Le prix de ce produit à changé`;

				const errorElement = errorMessage && (
					<span className={styles.error}>{errorMessage}</span>
				);

				return {
					id: l.code,
					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{l.nom}
						</div>
					),

					specialMessageOnMobile: errorElement,

					cells: [
						// Photo produit
						{ content: image },

						// Nom & interdit
						{
							content: (
								<>
									<p className={styles.nom}>{l.nom}</p>
									{errorElement}
								</>
							),
						},

						// Commentaire
						{ content: <EditCommentaire codeProduit={l.code} /> },

						// Labels
						{
							content: l.labels.length
								? l.labels.map((label, i) => <Label key={i}>{label}</Label>)
								: null,
						},

						// Prix unitaire
						{ content: <Prix prix={l.prixUnite} unite={l.uf} afficherNA /> },

						// Quantité
						{ content: <PlusMoinsPanier codeProduit={l.code} /> },

						// Total
						{
							content: (
								<Prix
									afficherNA
									prix={l.uc === l.uf ? l.prixUnite * l.quantite : undefined}
								/>
							),
						},

						// Supprimer le produit
						{
							content: (
								<BtnPoubelle
									title="Enlever le produit du panier"
									onClick={() => removeProduitFromPanier(l.code)}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

function mapStateToProps(state: AppState) {
	return {
		dateLivraison: state.panier.dateLivraison,
		lignes: state.panier.lignes.sort((a, b) => (a.nom > b.nom ? 1 : -1)),
	};
}

export default connect(mapStateToProps, { removeProduitFromPanier })(
	_TableProduits,
);
