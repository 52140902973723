import fr from 'date-fns/locale/fr';
import React, { forwardRef, ReactNode, Ref, useState } from 'react';
import ReactDatePicker, {
	ReactDatePickerProps,
	registerLocale,
} from 'react-datepicker';
import { generateRandomId } from '../../utils/generateRandomId';
import PopinPortal from '../Popin/PopinPortal';
import styles from './DatePicker.module.scss';
registerLocale('fr', fr);

type Props = Pick<
	ReactDatePickerProps,
	| 'selected'
	| 'className'
	| 'required'
	| 'readOnly'
	| 'minDate'
	| 'maxDate'
	| 'startDate'
	| 'endDate'
	| 'selectsEnd'
	| 'selectsStart'
	| 'isClearable'
> & {
	label?: ReactNode;
	onChange?: (date: Date | null) => void;
};

function DatePicker({ isClearable, ...props }: Props) {
	const [randomId] = useState(generateRandomId);
	//  Deblock the date for commercial
	const date = new Date();
	const nextDay = `${date.getFullYear()},${date.getMonth() + 1},${date.getDate() + 1}`;

	const CustomInput = forwardRef(
		({ value, onClick }: any, ref: Ref<HTMLInputElement>) => (
			<>
				<input
					ref={ref}
					readOnly
					id={randomId}
					value={value}
					onClick={onClick}
					placeholder="JJ/MM/YYYY"
					className={styles.input}
				/>
				{isClearable && value && props.onChange && (
					<button
						className={styles.clearButton}
						onClick={() => props.onChange!(null)}
					/>
				)}
			</>
		),
	);

	return (
		<div
			className={[
				styles.wrapper,
				props.className,
				props.readOnly && styles.readOnly,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{props.label && (
				<label className={styles.label} htmlFor={randomId}>
					{props.label}
				</label>
			)}

			<ReactDatePicker
				{...props}
				popperModifiers={{
					preventOverflow: {
						escapeWithReference: false,
						padding: { left: 20, right: 40 },
						boundariesElement: 'scrollParent',
					},
				}}
				locale="fr"
				autoComplete="off"
				dateFormat="dd/MM/yyyy"
				className={styles.datePicker}
				customInput={<CustomInput />}
				popperContainer={(props) => <PopinPortal {...props} />}
				onChange={(date: Date | null) => props.onChange && props.onChange(date)}
				//openToDate={new Date(`${nextDay}`)}
				// Set commercial true in the local to check if the user is a commercial
				minDate={localStorage.getItem('commercial') ? new Date() : new Date(`${nextDay}`)}
			/>


			{props.required && !props.selected && (
				<span className={styles.needed}>*</span>
			)}
		</div>
	);
}


export default DatePicker;
