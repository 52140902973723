import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'destyle.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import './styles/outdated.scss';
import './styles/style.scss';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
	apiKey: '1db55e213eb990df77017cc7146de70d',
	plugins: [new BugsnagPluginReact()]
  })

const ErrorBoundary = Bugsnag.getPlugin('react')!
.createErrorBoundary(React)

const store = configureStore();

const render = (Component: any) => {
	return ReactDOM.render(
		<ErrorBoundary>
		<Provider store={store}>
			<BrowserRouter>
				<Component />
			</BrowserRouter>
		</Provider>
		</ErrorBoundary>,
		document.getElementById('root'),
	);
};

render(App);


// Hot Module Reloading
if ((module as any).hot) {
	(module as any).hot.accept('./components/App/App', () => render(App));
}

// Service Worker
serviceWorker.register({
	// onSuccess: () => {
	// 	console.log(`Le serviceWorker a bien été installé`);
	// },
	onUpdate: () => {
		console.info(
			`Une mise à jour est disponible pour cette application. Veuillez fermer et rouvrir cet onglet pour avoir les dernières modifications.`,
		);
	},
});
