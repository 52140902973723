import formatDate from 'dateformat';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { LignePanier } from '../../store/panier/types';
import Button from '../Button/Button';
import TableWrapper from '../Table/TableWrapper/TableWrapper';
import styles from './CommandeEnCours.module.scss';
import Header from './Header/Header';
import PopinCreationCommande from './PopinCreationCommande/PopinCreationCommande';
import TableProduits from './TableProduits/TableProduits';

const heureLimite = 230; // HMM : Heure (0) Minute (00)

export interface Props {
	error?: string;
	loading: boolean;
	lignes: LignePanier[];
	dateLivraison?: Date;
}

export function _CommandeEnCours({
	error,
	lignes,
	loading,
	dateLivraison,
}: Props) {
	useTitle('Commande en cours');

	const [popinCreationOpen, setPopinCreationOpen] = useState(false);

	let validOrder = false;

	let invalidMessage = '';
	let livraisonMessage = <></>;

	const todayDate = new Date(new Date().toDateString());
	console.log('todayDate', todayDate);
	console.log('dateLivraison', dateLivraison);

	// Nécessaire pour unifier les timezones
	if (dateLivraison) dateLivraison = new Date(dateLivraison.toDateString());

	if (!dateLivraison) {
		invalidMessage = 'Veuillez choisir une date de livraison';
	} else if (dateLivraison < todayDate) {
		invalidMessage = `La date de livraison ne peut être antérieure à aujourd'hui`;
	} else if (!lignes.length) {
		invalidMessage = 'Veuillez mettre des produits dans votre panier';
	} else if (!!lignes.find((l) => l.interdit)) {
		invalidMessage = 'Certains produits dans votre panier vous sont interdits';
	} else if (!!lignes.find((l) => l.disabled)) {
		invalidMessage = `Certains produits dans votre panier n'existent pas`;
	} else {
		validOrder = true;

		const diff = dateLivraison.getTime() - todayDate.getTime();
		const diffInDays = diff / (1000 * 3600 * 24);

		const dateFormatee = formatDate(dateLivraison, 'dd/mm/yyyy');

		if (
			diffInDays === 0 && // si on est le jour J
			(dateLivraison.getDay() === 0 || // et que la livraison est un dimanche
				+formatDate(new Date(), 'HMM') >= heureLimite) // ou qu'on a dépassé l'heure limite
		) {
			const dateDemainFormatee = formatDate(
				new Date(dateLivraison).setDate(dateLivraison.getDate() + 1),
				'dd/mm/yyyy',
			);
			livraisonMessage = (
				<>
					En fonction de la disponibilité des stocks, vous recevrez
					l'intégralité de votre commande entre le{' '}
					<strong>{dateFormatee}</strong> et le{' '}
					<strong>{dateDemainFormatee}</strong>.
				</>
			);
		} else {
			livraisonMessage = (
				<>
					Votre commande vous sera livrée le <strong>{dateFormatee}</strong>.
				</>
			);
		}
	}

	return (
		<TableWrapper>
			{/* Header */}
			<Header />

			{/* Produits */}
			<TableProduits error={error} loading={loading} />

			{/* Footer */}
			<footer className={styles.footer}>
				{validOrder ? (
					<>
						<p className={styles.infoLivraison}>{livraisonMessage}</p>

						<Button
							inverted
							onClick={() => setPopinCreationOpen(true)}
							disabled={!!invalidMessage}
						>
							Valider la commande
						</Button>
					</>
				) : (
					<p className={styles.invalidOrder}>
						<span className={styles.exclamation}>!</span> {invalidMessage}
					</p>
				)}

				<PopinCreationCommande
					isOpen={popinCreationOpen}
					close={() => setPopinCreationOpen(false)}
				/>
			</footer>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return {
		lignes: state.panier.lignes,
		error: state.panier.errorInfoProduits,
		loading: state.panier.loadingInfoProduits,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default connect(mapStateToProps)(_CommandeEnCours);
